import React from 'react';


export default ({y, x, show, location, value}) => {

    let content;
    let tooltipStyles;
    if(value === null){
        content = '';
        tooltipStyles = {padding: '0px'}
    }
    else{
        content = 'Number of Treaties:  ' + value;
        tooltipStyles = {padding: '6px'}
    }
    
    return(
        <div style={{
                top: y, 
                left: x, 
                visibility: !show ? 'hidden' : '',
                position: 'fixed',
                color: 'black',
                pointerEvents: 'none',
                background: 'white',
                borderRadius: '4px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
                border: '1px solid black'
                }}>
            <div style={{
                    background: 'lightgrey', 
                    padding: '3px', 
                    borderRadius: '4px 4px 0 0',
                    fontWeight: "bold"
                    }}>
                {location}
            </div>
            <div style={tooltipStyles}>
                {content}
            </div>
        </div>
    )
}