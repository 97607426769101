import React from "react"
import styles from "../styles/all.module.css"
import Button from 'react-bootstrap/Button'
import map from "../assets/usMap.json"
import relationsData from "../assets/relations.json"
import countriesData from "../assets/countries.json"
import orgsData from "../assets/orgs.json"
import Sidebar from "react-sidebar";
import MapTooltip from '../components/MapTooltip';
import { scaleLinear } from "d3-scale";
import {
    ComposableMap,
    ZoomableGroup,
    Geographies,
    Geography,
    Lines,
    Line,
    Markers, 
    Marker,
  } from "react-simple-maps";

const wrapperStyles = {
    width: "100%",
    height: "calc(85vh)",
    margin: "0 auto",
}

class All extends React.Component {

    constructor(){
        super()

        this.state = {
            currentCountry: ``,
            currentTarget: ``,
            currentAllies: [],
            centerX: 0,
            centerY: 40,
            currentOrg: ``,
            currentOrgDescription: [],
            alliances: [],
            allAlliances: [],
            targets: [],
            sidebarOpen: false,
            zoom: 1,
            tooltip: {location: null, value: null, show: false}
        }

        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this); 
        this.selectOrg = this.selectOrg.bind(this);
    }

    componentDidMount(){
        let orgs = [];
        orgsData.forEach(function(element){
            orgs.push(element.AgreementName);
        });
        this.setState({
            allAlliances: orgs
        })
    }

    onSetSidebarOpen(open) {
        this.setState({ sidebarOpen: open });
    }

    clearSelection(){
        this.setState({
            currentCountry: ``,
            currentTarget: ``,
            currentAllies: [],
            currentOrg: ``,
            currentOrgDescription: [],
            alliances: [],
            targets: [],
        })
    }

    selectOrg(orgName){
        let tempAgreement;
        let tempDescription;
        let members = [];

        orgsData.forEach(function(element){
            if(element.AgreementName === orgName){
                tempAgreement = element.AgreementName;
                tempDescription = element.description;
            }
        });

        relationsData.forEach(function(element) {
            if(element.AgreementName === orgName){
                members.push(element.CountryName);
            }
        });

        this.setState({
            currentOrg: tempAgreement,
            currentOrgDescription: tempDescription,
            currentCountry: ``,
            currentTarget: ``,
            currentAllies: members,
            alliances: [],
            targets: [],
            currentTargetX: 0,
            currentTargetY: 0,
            currentOriginX: 0,
            currentOriginY: 0,
            sidebarOpen: true
        });
    }


    findConnections(countryName){
        let orgs = [];
        let currentOrg;
        //finds all agreements that currentCountry is in
        relationsData.forEach(function(element){
            if(element.CountryName === countryName){
                currentOrg = element.AgreementName;
                if(!orgs.includes(currentOrg)){
                    orgs.push(currentOrg);
                }
            }
        });

        return orgs;
    }

    findNeighbors(orgsList){
        let neighbors = {};

        //finds all other countries that currentCountry shares an agreement with
        relationsData.forEach(function(element){
            if(orgsList.includes(element.AgreementName)){
                if(!Object.keys(neighbors).includes(element.CountryName)){
                    neighbors[element.CountryName] = {name: element.CountryName, x: 0, y: 0, magnitude: 1}
                }
                else{
                    neighbors[element.CountryName].magnitude = neighbors[element.CountryName].magnitude + 1;
                }
            }
        });
        return neighbors; 
    }
    
    //This adds target coordinates to alliesList dict.
    findTargetCoordinates(alliesList){
        let targetCoordinates = [];
        let alliesNames = Object.keys(alliesList);
        //adds the allies to the targets list
        countriesData.forEach(function(element){
            if(alliesNames.includes(element.CountryName)){
                alliesList[element.CountryName].x = element.coorindates[0];
                alliesList[element.CountryName].y = element.coorindates[1];
            }
        });
        return targetCoordinates;
    }

    findEdgesBetweenCountries(currentCountryOrgs, targetCountry){
        let sharedOrgs = [];
        relationsData.forEach(function(element){
            if(element.CountryName === targetCountry 
                && !sharedOrgs.includes(element.AgreementName)
                && currentCountryOrgs.includes(element.AgreementName)){
                    sharedOrgs.push(element.AgreementName);
            }
        });
        return sharedOrgs;
    }

    handleClick = (geography) => {

        //Type check the input to differentiate the clicks from "Geography" objects and "Marker" objects
        let country;
        if(typeof geography != 'string'){
            country = geography.properties.name;
        }
        else{
            country = geography;
        }

        if(!this.state.currentCountry){
            let originX, originY;
            let orgsList = this.findConnections(country);
            let alliesList = this.findNeighbors(orgsList);
            this.findTargetCoordinates(alliesList);
    
            countriesData.forEach(function(element){
                if(element.CountryName === country){
                    originX = element.coorindates[0]
                    originY = element.coorindates[1];
                }
            });
    
            this.setState({
                currentCountry: country,
                currentOriginX: originX,
                currentOriginY: originY,
                currentTargetX: null,
                currentTargetY: null,
                targets: alliesList,
                currentOrg: '',
                currentOrgDescription: [],
                currentAllies: [],
                currentTarget: '',
            });
        }
        else{
            let targetX, targetY;
            countriesData.forEach(function(element){
                if(element.CountryName === country){
                    targetX = element.coorindates[0]
                    targetY = element.coorindates[1];
                }
            });

            let orgsList = this.findConnections(country);
            let commonAgreements = this.findEdgesBetweenCountries(orgsList, this.state.currentCountry);
            this.setState({
                currentTarget: country,
                currentTargetX: targetX,
                currentTargetY: targetY,
                alliances: commonAgreements,
                sidebarOpen: true
            });
        }

    }

    handleZoomIn = () => {
        if(this.state.zoom < 10){
            this.setState({
                zoom: this.state.zoom + 1
            })
        }
    }

    handleZoomOut = () => {
        if(this.state.zoom > 1){
            this.setState({
                zoom: this.state.zoom - 1
            })
        }
    }

    handleReset = () => {
        this.setState({
            zoom: 1,
            centerX: 0,
            centerY: 20
        })
    }

    showTooltip = (geography, evt) => {

        this.setState({ tooltip: {
                location: geography.properties.name,
                x: evt.clientX,
                y: evt.clientY-70,
                value: this.state.targets[geography.properties.name] ? this.state.targets[geography.properties.name].magnitude : null,
                show: true
            }
        })
    }

    hideTooltip = () => {
        this.setState({ tooltip: { location: null, value: null, show: false}});
    }

    renderLines = () => {

        const cityScale = scaleLinear().domain([1,10]).range(["#666","black"])
        const thickScale = scaleLinear().domain([1,10]).range([0.2, 1])


        if(this.state.targets){
            return Object.values(this.state.targets).map((target,i) => (
                <Line
                    key={i}
                    preserveMarkerAspect={false}
                    line={{
                        coordinates: {
                            start: [this.state.currentOriginX,this.state.currentOriginY],
                            end: [target.x,target.y]
                        }
                    }}
                    style={{
                        default: {
                            stroke: target.x === this.state.currentTargetX && target.y === this.state.currentTargetY 
                                    ? "tomato"
                                    : '#444',
                            strokeWidth: target.x === this.state.currentTargetX && target.y === this.state.currentTargetY
                                    ? 1.0
                                    : this.state.tooltip.location === target.name
                                    ? 0.8
                                    : 0.3, 
                            opacity: this.state.tooltip.location === target.name 
                                    ? 1.0
                                    : target.x === this.state.currentTargetX
                                    ? 1.0
                                    : 0.2,
                            outline: "none",
                            pointerEvents: "none"
                        },
                        hover: {
                            fill: "tomato",
                            stroke: cityScale(target.magnitude),
                            strokeWidth: thickScale(target.magnitude),
                            opacity: target.x === this.state.currentTargetX
                                    ? 1.0
                                    : 0.2, 
                            outline: "none",
                        },
                        pressed: {
                            fill: "tomato",
                            stroke: cityScale(target.magnitude),
                            strokeWidth: thickScale(target.magnitude),
                            opacity: target.x === this.state.currentTargetX
                                    ? 1.0
                                    : 0.2,
                            outline: "none",
                        },
                    }}
                />
            ))
        }
    };

    renderMarkers = () => {
        const thickScale = scaleLinear().domain([1,10]).range([1, 10])

        if(this.state.targets){
            return  Object.values(this.state.targets).map((target,i) => ( 
                        <Marker 
                            key={i}
                            onClick={() => this.handleClick(target.name)}
                            marker={{
                                coordinates: [target.x, target.y]
                            }}
                            style={{
                                default: { 
                                    fill: target.x === this.state.currentTargetX || target.x === this.state.currentOriginX 
                                        ? "#455A64"
                                        : "#455A64", 
                                    opacity: 1.0,
                                    stroke: target.x === this.state.currentTargetX || target.x === this.state.currentOriginX
                                    ? "gold"
                                    : "#455A64",
                                    outline: "none",
                                },
                                hover: {
                                    fill: "#FF5722",
                                    stroke: "#607D8B",
                                    outline: "none",
                                },
                                pressed: {
                                    fill: "#FF5722",
                                    stroke: "#607D8B",
                                    outline: "none",
                                },
                            }}>
                            <circle cx={0} cy={0} r={target.x === this.state.currentOriginX ? 3 : 1}/>
                        </Marker>
            ))
        }
    }

    populateSidebar = () => {
        if(this.state.alliances[0]){
            return (
                <div className="bg-light">
                    <Button className={styles.noBorderRadius +  " btn-danger btn-block p-0 m-0"} onClick={() => this.onSetSidebarOpen(false)}>Close</Button>
                    <div className="h3 p-4">
                        {"Agreements Between " +  this.state.currentCountry + " and " + this.state.currentTarget}
                    </div>
                    {this.state.alliances.map((alliance, i) => (
                        <Button className="btn-light btn-block p-0 m-0" key={i} onClick={() => this.selectOrg(alliance)}>{alliance}</Button>
                    ))}
                    <div className="h5 mt-5 bg-dark text-light">All Other Agreements</div>
                    {this.state.allAlliances.map((alliance, i) => (
                        <Button className="btn-light btn-block p-0 m-0" key={i} onClick={() => this.selectOrg(alliance)}>{alliance}</Button>
                    ))}
                </div>
            )
        }
        else if(this.state.currentOrg){
            return (
                <div className="bg-light">
                    <Button className={styles.noBorderRadius + " btn-danger btn-block p-0 m-0"} onClick={() => this.onSetSidebarOpen(false)}>Close</Button>
                    <div className="h3 border-bottom bg-dark text-light py-3">{this.state.currentOrg}</div>
                    <div className="m-3 text-left lead">
                        <p className="font-italic">Via Wikipedia</p>
                        {this.state.currentOrgDescription.map((paragraph) => (
                            <p>{paragraph}</p>
                        ))}
                    </div>
                    <div className="h5">Other Agreements</div>
                    {this.state.allAlliances.map((alliance, i) => (
                        <Button className="btn-light btn-block p-0 m-0" key={i} onClick={() => this.selectOrg(alliance)}>{alliance}</Button>
                    ))}
                </div>
            )
        }
        else {
            return (
            <div className="bg-light">
                <div>All Agreements</div>
                    {this.state.allAlliances.map((alliance, i) => (
                        <Button className="btn-light btn-block p-0 m-0" key={i} onClick={() => this.selectOrg(alliance)}>{alliance}</Button>
                    ))}
            </div>
            )
        }
    }

    renderTitle = () => {
        if(this.state.currentCountry && this.state.currentTarget){
            return <div className="h3">{this.state.currentCountry + "-" + this.state.currentTarget}</div>
        }
        else if(this.state.currentOrg){
            return <div className="h3">{this.state.currentOrg}</div>
        }
        else 
            return <div className="h3">Alliances of the World</div>
    }

    render() {
        const cityScale = scaleLinear().domain([1,7]).range(["#FFD740","green"]);

        return (
        <div className={styles.allContainer + " text-center"}>
            <Sidebar
                    sidebar={<b>{this.populateSidebar()}</b>}
                    open={this.state.sidebarOpen}
                    onSetOpen={this.onSetSidebarOpen}
                    styles={{ sidebar: {maxWidth: "500px"} }}
                > 
                <div className={styles.topbar + " bg-dark text-light"}>
                    <div className="h3 pt-1 m-0">{this.renderTitle()}</div>
                    <Button className="btn btn-sm mb-1 p-1 btn-dark" onClick={() => this.onSetSidebarOpen(true)}>
                        Sidebar
                    </Button>
                    <Button className="btn btn-sm mb-1 p-1 btn-dark" onClick={() => this.clearSelection()}>
                        Clear Selection
                    </Button> 
                    <Button className="btn btn-sm mb-1 p-1 btn-dark" onClick={() => this.handleZoomIn()}>
                        Zoom+
                    </Button>
                    <Button className="btn btn-sm mb-1 p-1 btn-dark" onClick={() => this.handleZoomOut()}>
                        Zoom-
                    </Button>
                    <Button className="btn btn-sm mb-1 p-1 btn-dark" onClick={() => this.handleReset()}>
                        Reset View
                    </Button>
                </div>
                <div style={wrapperStyles}>
                    <ComposableMap
                        height={551}
                        width={980}
                        projectionConfig={{
                            scale: 400,
                            rotation: [-11,0,0],
                        }}
                        style={{
                            height: "100%",
                            width: "100%",
                            backgroundColor: "white"
                    }}>
                        <ZoomableGroup center={[this.state.centerX,this.state.centerY]} zoom={this.state.zoom}>
                            <Geographies geography={map} disableOptimization>
                                {(geographies, projection) => geographies.map((geography, i) => geography.id !== "ATA" && (
                                    <Geography
                                        key={i}
                                        geography={geography}
                                        projection={projection}
                                        onClick={this.handleClick}
                                        onMouseMove={this.showTooltip}
                                        onMouseLeave={this.hideTooltip}
                                        style={{
                                            default: {
                                                fill: 
                                                    geography.properties.name === this.state.currentCountry
                                                    ? "green"
                                                    : this.state.currentAllies.includes(geography.properties.name)
                                                    ? "#FF6E40"
                                                    : geography.properties.name === this.state.currentTarget
                                                    ? "orange"
                                                    : this.state.targets[geography.properties.name]
                                                    ? cityScale(this.state.targets[geography.properties.name].magnitude)
                                                    : "#FFD740",
                                                stroke: "#607D8B",
                                                strokeWidth: 0.5,
                                                outline: "none"
                                            },
                                            hover: {
                                                fill: '#aaa',
                                                stroke: "#607D8B",
                                                strokeWidth: 0.5,
                                                outline: "none",
                                            },
                                            pressed: {
                                                fill: "#FF5722",
                                                stroke: "#607D8B",
                                                strokeWidth: 0.5,
                                                outline: "none",
                                                transition: "100s"
                                            },
                                        }}
                                    />
                                ))}
                            </Geographies>
                            <Lines>{this.renderLines()}</Lines>
                            <Markers>{this.renderMarkers()}</Markers>
                        </ZoomableGroup>
                    </ComposableMap>
                    <MapTooltip {...this.state.tooltip}/>
                    <div className={styles.bottomBar + " bg-primary text-light text-bold lead"}>
                        Click on a country
                    </div>
                </div>
            </Sidebar>
        </div>
        )
    }
}

export default All;


